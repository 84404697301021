
import {
  Component, Inject, Prop, Vue,
} from 'nuxt-property-decorator'
import IMedia from '../shared/general/interfaces/IMedia'
import IImageVideoContent from '../shared/general/interfaces/IImageVideoContent'
import { openLightbox } from '../shared/general/services/LightboxService'

@Component({
  name: 'TextImage',
  components: {
    BaseImageVideo: () => import('./ImageVideo/BaseImageVideo.vue'),
    BaseHeadline: () => import('./base/BaseHeadline.vue'),
  },
})
export default class TextImage extends Vue {
  @Prop() media ?: IMedia

  @Prop() caption ?: string

  @Prop({ required: true }) alignment! : 'left' | 'right' | 'centered'

  @Prop({ default: '' }) headline! : string

  @Prop({ default: '' }) introText! : string

  @Inject({ default: '1' }) containerColumns ?: '1' | '2' | '3'

  private get imageVideoAlignment () : string {
    if ((!this.containerColumns || this.containerColumns === '1') && this.alignment === 'left') {
      return 'w-full md:float-left md:w-1/2 md:mr-6'
    }
    if ((!this.containerColumns || this.containerColumns === '1') && this.alignment === 'right') {
      return 'w-full md:float-right md:w-1/2 md:ml-6'
    }
    return 'w-full mx-auto'
  }

  private showLightbox (content : IImageVideoContent) : void {
    if (!this.media?.lightbox && this.media?.mediaType !== 'video') return

    openLightbox(content)
  }
}
